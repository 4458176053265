import { gql } from '@apollo/client';

export const CREATE_USER_TYPE = gql`
  mutation createUserType(
    $approvalRequired: Boolean,
    $name: String!,
    $permission: EMemberPermission!,
    $projectId: String!,
    $userTypeCode: String,
    $userFlowId: Int,
  ) {
    userType {
      createUserType(userType: { 
        approvalRequired: $approvalRequired,
        name: $name,
        permission: $permission,
        projectId: $projectId,
        userTypeCode: $userTypeCode,
        userFlowId: $userFlowId,,
      }) {
        id
        name
        status
        permission
      }
    }
  }
`;

