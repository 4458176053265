

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPSERT_USER_TYPE_SETTINGS } from './useUpsertUserTypeSettings.graphql';
import {
  IUseUpsertUserTypeSettings,
  IUpsertUserTypeSettingsMutationGraphQLResponse,
  IUseUpsertUserTypeSettingsOptions,
  IUpsertUserTypeSettingsMutationVariables,
  IHandleUpsertUserTypeSettingsParams,
} from './useUpsertUserTypeSettings.interfaces';

const useUpsertUserTypeSettings = (options?: IUseUpsertUserTypeSettingsOptions): IUseUpsertUserTypeSettings => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpsertUserTypeSettingsOptions);

  const [executeUpsertUserTypeSettings, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpsertUserTypeSettingsMutationGraphQLResponse,
    IUpsertUserTypeSettingsMutationVariables
  >({
    mutation: mutation ?? UPSERT_USER_TYPE_SETTINGS,
    options: {
      onCompleted: ({
        userType: {
          securitySettings: {
            addOrUpdate: securitySetting, 
          }
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          securitySetting,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpsertUserTypeSettings = async ({
    userTypeId,
    request,
  }: IHandleUpsertUserTypeSettingsParams) => {
    await executeUpsertUserTypeSettings({
      variables: {
        userTypeId,
        request,
      },
    });
  };

  return {
    handleUpsertUserTypeSettings,
    isLoading,
  };
};

export { useUpsertUserTypeSettings };
