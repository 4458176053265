import { gql } from '@apollo/client';

const GET_COUNTRY_CODES = gql`
  query getCountryCodes {
    __type(name: "ECountries") {
      enumValues {
        name
        description
      }
    }
  }
`;

export { GET_COUNTRY_CODES };
