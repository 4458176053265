import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { BULK_DELETE_GROUPS_MUTATION } from './useBulkDeleteGroups.graphql';
import {
  IBulkDeleteGroupsMutationGraphQLResponse,
  IBulkDeleteGroupsMutationVariables,
  IHandleBulkDeleteGroupsParams,
  IUseBulkDeleteGroupsOptions,
  IUseBulkDeleteGroups,
} from './useBulkDeleteGroups.interfaces';

const useBulkDeleteGroups = (options?: IUseBulkDeleteGroupsOptions): IUseBulkDeleteGroups => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkDeleteGroupsOptions);

  const [executeBulkDeleteGroups, { loading: isLoading }] = useLoggedGeladaMutation<
    IBulkDeleteGroupsMutationGraphQLResponse,
    IBulkDeleteGroupsMutationVariables
  >({
    mutation: mutation ?? BULK_DELETE_GROUPS_MUTATION,
    options: {
      onCompleted: ({ group: { bulkDeleteGroups: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkDeleteGroups = async ({ groupIds }: IHandleBulkDeleteGroupsParams) => {
    await executeBulkDeleteGroups({
      variables: {
        groupIds,
      },
    });
  };

  return {
    handleBulkDeleteGroups,
    isLoading,
  };
};

export { useBulkDeleteGroups };
