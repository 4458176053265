import { gql } from '@apollo/client';

export const UPSERT_USER_TYPE_SETTINGS = gql`
  mutation upsertUserTypeSettings(
    $userTypeId: Int!,
    $request: SecuritySettingInput!,
  ) {
    userType {
      securitySettings {
        addOrUpdate(userTypeId: $userTypeId, request: $request) {
          minimumLength
          mustHaveMfa
          mustHaveNumber
          mustHaveSpecialCharacters
          mustHaveUpperCase
          mustMatchPasswords
        }
      }
    }
  }
`;

