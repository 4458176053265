import { useEffect, useState } from 'react';

import { getItemWithExpiry } from '@netfront/common-library';
import { IUser, useAuthentication, useGetLoggedUser, useGetProduct } from '@netfront/gelada-identity-library';


import UserContext from './UserContext';
import { UserContextProps } from './UserContext.interfaces';

import { useHasPartner } from '../../hooks';

export function UserProvider({ children }: Readonly<UserContextProps>) {
  const { isAuthenticated } = useAuthentication();
  const { getProduct } = useGetProduct();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [fullName, setFullName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>();
  const [projectExpiryStored, setProjectExpiryStored] = useState<number>();
  const [storedUser, setStoredUser] = useState<IUser>();
  const [user, setUser] = useState<IUser>();
  const [userId, setUserId] = useState<number>();
  const [hasPartner, setHasPartner] = useState<boolean>(false);

  const { handleHasPartner } = useHasPartner({
    onCompleted(data) {
      const { hasPartner: hasAnyPartner = false} = data;
      setHasPartner(hasAnyPartner);
    },
  });

  const { handleGetLoggedUser } = useGetLoggedUser({
    onCompleted: ({ user: returnedUser }) => {
      setStoredUser(returnedUser);
    },
    product: getProduct(),
  });

  useEffect(() => {
    const locallyStoredUser = localStorage.getItem('storedUser');

    if (locallyStoredUser) {
      const parsedUserWithExpiry = JSON.parse(locallyStoredUser);
      const parsedUser = JSON.parse(String(parsedUserWithExpiry.value ?? ''));
      setProjectExpiryStored(Number(parsedUserWithExpiry.expiry));
      const { profileImageUrl: image, credential, firstName: returnedFirstName, lastName: returnedLastName, id } = parsedUser;
      const { email: returnedEmail, phoneNumber: returnedPhoneNumber } = credential;
      setProfileImageUrl(decodeURIComponent(String(image)));
      setFullName(`${String(returnedFirstName)} ${String(returnedLastName)}`);
      setFirstName(String(returnedFirstName));
      setLastName(String(returnedLastName));
      setEmail(String(returnedEmail));
      setPhoneNumber(String(returnedPhoneNumber));
      setUserId(Number(id));
      setUser(parsedUser as IUser);
    }
  }, []);

  useEffect(() => {
    if (storedUser) {
      const { pictures, credential, firstName: returnedFirstName, lastName: returnedLastName, id } = storedUser;

      const { email: returnedEmail, phoneNumber: returnedPhoneNumber } = credential;

      const { originalPicture, profilePicture } = pictures ?? {};

      const image = !profilePicture?.presignedUrl ? originalPicture?.presignedUrl ?? '' : profilePicture.presignedUrl;

      localStorage.setItem(
        'storedUser',
        getItemWithExpiry(
          JSON.stringify({
            id,
            firstName: returnedFirstName,
            lastName: returnedLastName,
            credential: {
              email: returnedEmail,
              phoneNumber: returnedPhoneNumber,
            },
            profileImageUrl: encodeURIComponent(image),
          }),
          {
            currentTimeValueInMilliseconds: new Date().getTime(),
            expiryTime: {
              unit: 'days',
              value: 1,
            },
          },
        ),
      );

      setProfileImageUrl(image);
      setFullName(`${returnedFirstName} ${returnedLastName}`);
      setFirstName(returnedFirstName);
      setLastName(returnedLastName);
      setEmail(returnedEmail);
      setUserId(Number(id));
      setPhoneNumber(returnedPhoneNumber);
      setUser(storedUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedUser]);

  useEffect(() => {
    if (!projectExpiryStored) {
      return;
    }

    if (new Date().getTime() < projectExpiryStored) {
      return;
    }

    if (!isAuthenticated) return;

    void handleGetLoggedUser({ shouldIncludeUserPictures: true });
    void handleHasPartner({
      product: getProduct() ?? 'EKARDO',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectExpiryStored]);

  const getUser = () => {
    return user;
  };

  return (
    <UserContext.Provider
      value={{
        userId,
        firstName,
        lastName,
        email,
        phoneNumber,
        profileImageUrl,
        fullName,
        setProfileImageUrl,
        setStoredUser,
        user,
        getUser,
        hasPartner,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
