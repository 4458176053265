import { gql } from '@apollo/client';

export const UPDATE_CUSTOM_FIELD = gql`
  mutation updateCustomField(
    $customFieldId: Int!,
    $isRequired: Boolean!,
    $name: String!,
    $scope: ECustomFieldScope!,
    $description: String,
    $isPubliclyAvailable: Boolean
  ) {
    customField {
      update(
        customFieldId: $customFieldId,
        isRequired: $isRequired, 
        name: $name,
        scope: $scope,
        description: $description,
        isPubliclyAvailable: $isPubliclyAvailable
      ) 
    }
  }
`;

