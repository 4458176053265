import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { BULK_RESEND_INVITATION_MUTATION } from './useBulkResendInvitations.graphql';
import {
  IBulkResendInvitationsMutationGraphQLResponse,
  IBulkResendInvitationsMutationVariables,
  IHandleBulkResendInvitationsParams,
  IUseBulkResendInvitationsOptions,
  IUseBulkResendInvitations,
} from './useBulkResendInvitations.interfaces';

const useBulkResendInvitations = (options?: IUseBulkResendInvitationsOptions): IUseBulkResendInvitations => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkResendInvitationsOptions);

  const [executeBulkResendInvitations, { loading: isLoading }] = useLoggedGeladaMutation<
    IBulkResendInvitationsMutationGraphQLResponse,
    IBulkResendInvitationsMutationVariables
  >({
    mutation: mutation ?? BULK_RESEND_INVITATION_MUTATION,
    options: {
      onCompleted: ({ invitation: { resendInvitations: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkResendInvitations = async ({ invitationIds }: IHandleBulkResendInvitationsParams) => {
    await executeBulkResendInvitations({
      variables: {
        invitationIds,
      },
    });
  };

  return {
    handleBulkResendInvitations,
    isLoading,
  };
};

export { useBulkResendInvitations };
