import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_TYPE } from './useGetUserType.graphql';
import {
  IGetUserTypeQueryGraphQLResponse,
  IGetUserTypeQueryVariables,
  IHandleGetUserTypeParams,
  IUseGetUserType,
  IUseGetUserTypeOptions,
} from './useGetUserType.interfaces';

const useGetUserType = (options?: IUseGetUserTypeOptions): IUseGetUserType => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetUserTypeOptions);

  const [executeGetUserType, { loading: isLoading }] = useLoggedGeladaLazyQuery<
  IGetUserTypeQueryGraphQLResponse,
  IGetUserTypeQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ userType: { getUserType: userType } }) => {
        if (!onCompleted) {
          return;
        }

        void onCompleted({
          userType,
        });
      },
      onError,
    },
    query: query ?? GET_USER_TYPE,
    token,
  });

  

  const handleGetUserType = async ({ userTypeId }: IHandleGetUserTypeParams) => {
    await executeGetUserType({
      variables: {
        userTypeId
      },
    });
  };

  return {
    handleGetUserType,
    isLoading,
  };
};

export { useGetUserType };
