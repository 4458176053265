
import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_USER_TYPE } from './useUpdateUserType.graphql';
import {
  IUseUpdateUserType,
  IUpdateUserTypeMutationGraphQLResponse,
  IUseUpdateUserTypeOptions,
  IUpdateUserTypeMutationVariables,
  IHandleUpdateUserTypeParams,
} from './useUpdateUserType.interfaces';

const useUpdateUserType = (options?: IUseUpdateUserTypeOptions): IUseUpdateUserType => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdateUserTypeOptions);

  const [executeUpdateUserType, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateUserTypeMutationGraphQLResponse,
    IUpdateUserTypeMutationVariables
  >({
    mutation: mutation ?? UPDATE_USER_TYPE,
    options: {
      onCompleted: ({
        userType: {
          updateUserType: userType,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userType,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateUserType = async ({
    approvalRequired,
    canEditSettings = false,
    name,
    permission,
    securitySetting,
    status,
    userTypeId,  
    userFlowId,

  }: IHandleUpdateUserTypeParams) => {
    await executeUpdateUserType({
      variables: {
        approvalRequired,
        canEditSettings,
        name,
        permission,
        securitySetting,
        status,
        userTypeId,
        userFlowId,
      },
    });
  };

  return {
    handleUpdateUserType,
    isLoading,
  };
};

export { useUpdateUserType };
