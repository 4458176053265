import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { BULK_UPDATE_GROUP_STATUS_MUTATION } from './useBulkUpdateGroupStatus.graphql';
import {
  IBulkUpdateGroupStatusMutationGraphQLResponse,
  IBulkUpdateGroupStatusMutationVariables,
  IHandleBulkUpdateGroupStatusParams,
  IUseBulkUpdateGroupStatusOptions,
  IUseBulkUpdateGroupStatus,
} from './useBulkUpdateGroupStatus.interfaces';

const useBulkUpdateGroupStatus = (options?: IUseBulkUpdateGroupStatusOptions): IUseBulkUpdateGroupStatus => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkUpdateGroupStatusOptions);

  const [executeBulkUpdateGroupStatus, { loading: isLoading }] = useLoggedGeladaMutation<
    IBulkUpdateGroupStatusMutationGraphQLResponse,
    IBulkUpdateGroupStatusMutationVariables
  >({
    mutation: mutation ?? BULK_UPDATE_GROUP_STATUS_MUTATION,
    options: {
      onCompleted: ({ group: { bulkUpdateGroupStatus: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkUpdateGroupStatus = async ({ groupIds, status }: IHandleBulkUpdateGroupStatusParams) => {
    await executeBulkUpdateGroupStatus({
      variables: {
        groupIds,
        status,
      },
    });
  };

  return {
    handleBulkUpdateGroupStatus,
    isLoading,
  };
};

export { useBulkUpdateGroupStatus };
