import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { REMOVE_OPTION_FROM_MULTI_RESPONSE } from './useRemoveOptionFromMultiResponse.graphql';
import {
  IUseRemoveOptionFromMultiResponse,
  IRemoveOptionFromMultiResponseMutationGraphQLResponse,
  IUseRemoveOptionFromMultiResponseOptions,
  IRemoveOptionFromMultiResponseMutationVariables,
  IHandleRemoveOptionFromMultiResponseParams,
} from './useRemoveOptionFromMultiResponse.interfaces';

const useRemoveOptionFromMultiResponse = (options?: IUseRemoveOptionFromMultiResponseOptions): IUseRemoveOptionFromMultiResponse => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseRemoveOptionFromMultiResponseOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    IRemoveOptionFromMultiResponseMutationGraphQLResponse,
    IRemoveOptionFromMultiResponseMutationVariables
  >({
    mutation: mutation ?? REMOVE_OPTION_FROM_MULTI_RESPONSE,
    options: {
      onCompleted: ({
        customField: {
          removeOptionIntoMultiResponse: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleRemoveOptionFromMultiResponse = async ({
    responseId
  }: IHandleRemoveOptionFromMultiResponseParams) => {
    await executeCreateGroupInvitation({
      variables: {
        responseId,
      },
    });
  };

  return {
    handleRemoveOptionFromMultiResponse,
    isLoading,
  };
};

export { useRemoveOptionFromMultiResponse };
