import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { DELETE_USER_TYPE } from './useDeleteUserType.graphql';
import {
  IUseDeleteUserType,
  IDeleteUserTypeMutationGraphQLResponse,
  IUseDeleteUserTypeOptions,
  IDeleteUserTypeMutationVariables,
  IHandleDeleteUserTypeParams,
} from './useDeleteUserType.interfaces';

const useDeleteUserType = (options?: IUseDeleteUserTypeOptions): IUseDeleteUserType => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseDeleteUserTypeOptions);

  const [executeDeleteUserType, { loading: isLoading }] = useLoggedGeladaMutation<
    IDeleteUserTypeMutationGraphQLResponse,
    IDeleteUserTypeMutationVariables
  >({
    mutation: mutation ?? DELETE_USER_TYPE,
    options: {
      onCompleted: ({
        userType: {
          deleteUserType: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleDeleteUserType = async ({
    userTypeId,
  }: IHandleDeleteUserTypeParams) => {
    await executeDeleteUserType({
      variables: {
        userTypeId,
      },
    });
  };

  return {
    handleDeleteUserType,
    isLoading,
  };
};

export { useDeleteUserType };