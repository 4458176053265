

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_USER_TYPE } from './useCreateUserType.graphql';
import {
  IUseCreateUserType,
  ICreateUserTypeMutationGraphQLResponse,
  IUseCreateUserTypeOptions,
  ICreateUserTypeMutationVariables,
  IHandleCreateUserTypeParams,
} from './useCreateUserType.interfaces';

const useCreateUserType = (options?: IUseCreateUserTypeOptions): IUseCreateUserType => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateUserTypeOptions);

  const [executeCreateUserType, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateUserTypeMutationGraphQLResponse,
    ICreateUserTypeMutationVariables
  >({
    mutation: mutation ?? CREATE_USER_TYPE,
    options: {
      onCompleted: ({
        userType: {
          createUserType: userType,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          userType,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateUserType = async ({
    approvalRequired,
    name,
    permission,
    projectId,
    userTypeCode,
    userFlowId,
  }: IHandleCreateUserTypeParams) => {
    await executeCreateUserType({
      variables: {
        approvalRequired,
        name,
        permission,
        projectId,
        userTypeCode,
        userFlowId,
      },
    });
  };

  return {
    handleCreateUserType,
    isLoading,
  };
};

export { useCreateUserType };
