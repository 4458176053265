import { useEffect, useState } from 'react';

import { getAccessTokenCookie, useJwtDecoder } from '@netfront/common-library';

export const useUserIsAdmin = () => {
  const ADMINISTRATOR = 'Administrator';

  const [isAdmin, setIsAdmin] = useState(false);
  const { getDecodedJwt } = useJwtDecoder();
  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }
    
    try {
      const decoded = getDecodedJwt(String(accessToken));
      
      // Check if decoded exists and has role property
      if ('role' in decoded) {
        const { role } = decoded as { role: string };
        setIsAdmin(role === ADMINISTRATOR);
      }
    } catch (error) {
      console.error('Error decoding JWT:', error);
      setIsAdmin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAdmin };
};
