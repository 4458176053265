import { gql } from '@apollo/client';

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail(
    $projectId: String!,
    $displayName: String!,
    $email: String!,
    $replyTo: String!,
  ) {
    project {
      verifyEmail(
        projectId: $projectId,
        displayName: $displayName,
        email: $email,
        replyTo: $replyTo,
      )
    }
  }
`;

