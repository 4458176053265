import { gql } from '@apollo/client';

const GET_HAS_PARTNER = gql`
  query hasPartner($product: EProduct!) {
    partner {
      hasPartner(product: $product)
    }
  }
`;

export { GET_HAS_PARTNER };
