import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { BULK_UPDATE_ROLE_STATUS } from './useBulkUpdateUserTypeStatus.graphql';
import {
  IBulkUpdateUserTypeStatusMutationGraphQLResponse,
  IBulkUpdateUserTypeStatusMutationVariables,
  IHandleBulkUpdateUserTypeStatusParams,
  IUseBulkUpdateUserTypeStatusOptions,
  IUseBulkUpdateUserTypeStatus,
} from './useBulkUpdateUserTypeStatus.interfaces';

const useBulkUpdateUserTypeStatus = (options?: IUseBulkUpdateUserTypeStatusOptions): IUseBulkUpdateUserTypeStatus => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkUpdateUserTypeStatusOptions);

  const [executeBulkUpdateUserTypeStatus, { loading: isLoading }] = useLoggedGeladaMutation<
    IBulkUpdateUserTypeStatusMutationGraphQLResponse,
    IBulkUpdateUserTypeStatusMutationVariables
  >({
    mutation: mutation ?? BULK_UPDATE_ROLE_STATUS,
    options: {
      onCompleted: ({ userType: { bulkUpdateStatus: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkUpdateUserTypeStatus = async ({ userTypeIds, status }: IHandleBulkUpdateUserTypeStatusParams) => {
    await executeBulkUpdateUserTypeStatus({
      variables: {
        status,
        userTypeIds,
      },
    });
  };

  return {
    handleBulkUpdateUserTypeStatus,
    isLoading,
  };
};

export { useBulkUpdateUserTypeStatus };
