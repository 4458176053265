import { gql } from '@apollo/client';

export const GET_USER_TYPE = gql`
  query getUserType($userTypeId: Int!) {
    userType {
      getUserType(userTypeId: $userTypeId) {
        id
        name
        permission
        status
        securitySetting {
          mustHaveMfa
          mustHaveNumber
          mustHaveSpecialCharacters
          mustHaveUpperCase
          minimumLength
          mustMatchPasswords
        }
      }
    }
  }
`;