import { gql } from '@apollo/client';

export const UPDATE_USER_TYPE = gql`
  mutation updateUserType(
    $approvalRequired: Boolean!,
    $canEditSettings: Boolean!,
    $name: String!,
    $permission: EMemberPermission!,
    $securitySetting: SecuritySettingInput!,
    $status: EUserTypeStatus!,
    $userTypeId: Int!,
    $userFlowId: Int
  ) {
    userType {
      updateUserType(
        userType: { 
          userTypeId: $userTypeId, 
          name: $name, 
          permission: $permission, 
          approvalRequired: $approvalRequired, 
          status: $status,
          userFlowId: $userFlowId
        }
      ) {
        id
        projectId
        name
        userTypeCode
        permission
        status
      }

      securitySettings {
        addOrUpdate(userTypeId: $userTypeId, request: $securitySetting)
          @include(if: $canEditSettings) {
          minimumLength
          mustHaveMfa
          mustHaveNumber
          mustHaveSpecialCharacters
          mustHaveUpperCase
          mustMatchPasswords
        }
      }
    }
  }
`;
