import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_COUNTRY_CODES } from './useGetCountryCodes.graphql';
import {
  IGetCountryCodesQueryGraphQLResponse,
  IUseGetCountryCodes,
  IUseGetCountryCodesOptions,
} from './useGetCountryCodes.interfaces';

const useGetCountryCodes = (options?: IUseGetCountryCodesOptions): IUseGetCountryCodes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetCountryCodesOptions);

  const [executeGetCountryCodes, { loading: isLoading }] = useLoggedGeladaLazyQuery<IGetCountryCodesQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          __type: { enumValues },
        } = data;

        void onCompleted({ enumValues });
      },
      onError,
    },
    query: query ?? GET_COUNTRY_CODES,
    token,
  });

  const handleGetCountryCodes = () => {
    void executeGetCountryCodes();
  };

  return {
    handleGetCountryCodes,
    isLoading,
  };
};

export { useGetCountryCodes };
