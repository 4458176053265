import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useToast } from '@netfront/ekardo-content-library';
import { DBMembershipPermissionType, IDBUserType } from '@netfront/gelada-identity-library';
import {  useCreateUserType, useUpdateUserType, useUpsertUserTypeSettings } from 'hooks';

const useUpsertUserType = ({ onUpdate }: { onUpdate: () => void }) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [securitySettings, setSecuritySettings] = useState<IDBUserType['securitySetting']>();
  const [canUserEditSettings, setCanUserEditSettings] = useState<boolean>(false);

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const cleanUpAndReturn = (isCreate = true): void => {
    setSecuritySettings(undefined);
    setCanUserEditSettings(false);
    handleToastSuccess({
      message: `Role successfully ${isCreate ? 'created' : 'updated'}`,
    });
    onUpdate();
  }

  const { handleUpsertUserTypeSettings, isLoading: isUpsertUserTypeSettingsLoading = false } = useUpsertUserTypeSettings({
    onCompleted: () => {
      cleanUpAndReturn(true);
    },
    onError: handleGetError,
  });

  const { handleCreateUserType, isLoading: isCreateUserTypeLoading = false } = useCreateUserType({
    onCompleted: ({ userType }) => {
      if (securitySettings && canUserEditSettings) {
        void handleUpsertUserTypeSettings({
          userTypeId: userType.id,
          request: securitySettings,
        });

      } else {
        cleanUpAndReturn(true);
      }
      
    },
    onError: handleGetError,
  });


  const { handleUpdateUserType, isLoading: isUpdateUserTypeLoading = false } = useUpdateUserType({
    onCompleted: () => {
      cleanUpAndReturn(false);
    },
    onError: handleGetError,
  });

  const handleUpsertUserType = ({ 
    canEditSettings = false,
    isApprovalRequired = false,
    name,
    permission,
    projectId, 
    securitySetting,
    status,
    userFlowId,
    userTypeCode,
    userTypeId,
  }: {
    canEditSettings?: boolean;
    isApprovalRequired?: boolean;
    name: string;
    permission: DBMembershipPermissionType;
    projectId: string;
    securitySetting: IDBUserType['securitySetting'];
    status: IDBUserType['status'];
    userFlowId?: number;
    userTypeCode?: string;
    userTypeId?: number;

  }) => {

    setSecuritySettings(securitySetting);
    setCanUserEditSettings(canEditSettings);
    

    if (!userTypeId) {
      void handleCreateUserType({
        projectId,
        approvalRequired: isApprovalRequired,
        name,
        permission,
        userTypeCode,
        userFlowId,
      })
    } else {

      void handleUpdateUserType({
        approvalRequired: isApprovalRequired,
        name,
        permission,
        status,
        userTypeId,
        canEditSettings,
        securitySetting,
        userFlowId,
      });
    }
  };

  return {
    isLoading: 
      isCreateUserTypeLoading || 
      isUpdateUserTypeLoading ||
      isUpsertUserTypeSettingsLoading,
    handleUpsertUserType,
  };
};
export { useUpsertUserType };
