import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { BULK_DELETE_USER_TYPES_MUTATION } from './useBulkDeleteUserTypes.graphql';
import {
  IBulkDeleteUserTypesMutationGraphQLResponse,
  IBulkDeleteUserTypesMutationVariables,
  IHandleBulkDeleteUserTypesParams,
  IUseBulkDeleteUserTypesOptions,
  IUseBulkDeleteUserTypes,
} from './useBulkDeleteUserTypes.interfaces';

const useBulkDeleteUserTypes = (options?: IUseBulkDeleteUserTypesOptions): IUseBulkDeleteUserTypes => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkDeleteUserTypesOptions);

  const [executeBulkDeleteUserTypes, { loading: isLoading }] = useLoggedGeladaMutation<
    IBulkDeleteUserTypesMutationGraphQLResponse,
    IBulkDeleteUserTypesMutationVariables
  >({
    mutation: mutation ?? BULK_DELETE_USER_TYPES_MUTATION,
    options: {
      onCompleted: ({ userType: { bulkDelete: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkDeleteUserTypes = async ({ userTypeIds }: IHandleBulkDeleteUserTypesParams) => {
    await executeBulkDeleteUserTypes({
      variables: {
        userTypeIds,
      },
    });
  };

  return {
    handleBulkDeleteUserTypes,
    isLoading,
  };
};

export { useBulkDeleteUserTypes };
