import { useHowlerMutation } from 'hooks';

import { VERIFY_EMAIL_MUTATION } from './useVerifyEmail.graphql';
import {
  IUseVerifyEmail,
  IVerifyEmailMutationGraphQLResponse,
  IUseVerifyEmailOptions,
  IVerifyEmailMutationVariables,
  IHandleVerifyEmailParams,
} from './useVerifyEmail.interfaces';

const useVerifyEmail = (options?: IUseVerifyEmailOptions): IUseVerifyEmail => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseVerifyEmailOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useHowlerMutation<
    IVerifyEmailMutationGraphQLResponse,
    IVerifyEmailMutationVariables
  >({
    mutation: mutation ?? VERIFY_EMAIL_MUTATION,
    options: {
      onCompleted: ({
        project: {
          verifyEmail: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleVerifyEmail = async ({
    projectId,
    displayName,
    email,
    replyTo,
  }: IHandleVerifyEmailParams) => {
    await executeCreateGroupInvitation({
      variables: {
        projectId,
        displayName,
        email,
        replyTo,
      },
    });
  };

  return {
    handleVerifyEmail,
    isLoading,
  };
};

export { useVerifyEmail };
