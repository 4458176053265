import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_HAS_PARTNER } from './useHasPartner.graphql';
import { IHasPartnerQueryGraphQLResponse, IHasPartnerQueryVariables, IUseHasPartner, IUseHasPartnerOptions } from './useHasPartner.interfaces';


const useHasPartner = (options?: IUseHasPartnerOptions): IUseHasPartner => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseHasPartnerOptions);

  const [executeHasPartner, { loading: isLoading }] = useLoggedGeladaLazyQuery<IHasPartnerQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }
        const {
          partner: { hasPartner },
        } = data;

        onCompleted({
          hasPartner,
        });
      },
      onError,
    },
    query: query ?? GET_HAS_PARTNER,
    token,
  });

  const handleHasPartner = async ({ product }: IHasPartnerQueryVariables) => {
    await executeHasPartner({
      variables: {
        product
      }
    });
  };

  return {
    handleHasPartner,
    isLoading,
  };
};

export { useHasPartner };
