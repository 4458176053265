import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_CUSTOM_FIELD } from './useUpdateCustomField.graphql';
import {
  IUseUpdateCustomField,
  IUpdateCustomFieldMutationGraphQLResponse,
  IUseUpdateCustomFieldOptions,
  IUpdateCustomFieldMutationVariables,
  IHandleUpdateCustomFieldParams,
} from './useUpdateCustomField.interfaces';

const useUpdateCustomField = (options?: IUseUpdateCustomFieldOptions): IUseUpdateCustomField => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdateCustomFieldOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateCustomFieldMutationGraphQLResponse,
    IUpdateCustomFieldMutationVariables
  >({
    mutation: mutation ?? UPDATE_CUSTOM_FIELD,
    options: {
      onCompleted: ({
        customField: {
          update: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateCustomField = async ({
    customFieldId,
    description,
    isRequired,
    name,
    isPubliclyAvailable,
    scope,
  }: IHandleUpdateCustomFieldParams) => {
    await executeCreateGroupInvitation({
      variables: {
        customFieldId,
        description,
        isRequired,
        name,
        isPubliclyAvailable,
        scope,
      },
    });
  };

  return {
    handleUpdateCustomField,
    isLoading,
  };
};

export { useUpdateCustomField };
