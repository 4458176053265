import { gql } from '@apollo/client';

const GET_VERIFIED_EMAILS_QUERY = gql`
  query getVerifiedEmails($projectId: String!) {
    project {
      listVerifiedEmails(projectId: $projectId) {
        displayName
        email
        replyTo
        isVerified
      }
    }
  }
`;


export { GET_VERIFIED_EMAILS_QUERY };
